import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Question } from '../shared/question.model';
import { QuestionService } from '../shared/question.service';
import { FiltersService } from '../../candidatures/shared/filters.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Technology } from '../shared/technology.model';
import { Subscription } from 'rxjs';
import { DeleteQuestionDialogComponent } from '../delete-question-dialog/delete-question-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { SubTechnology } from '../../candidatures/shared/subtechnology';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { SubTechnologyService } from '../shared/sub-technology.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { DeleteSubTechnoDialogComponent } from '../delete-sub-techno-dialog/delete-sub-techno-dialog.component';

@Component({
  selector: 'app-subtechnology-questions',
  templateUrl: './subtechnology-questions.component.pug',
  styleUrls: ['./subtechnology-questions.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class SubtechnologyQuestionsComponent implements OnInit {
  alpha: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<Question>;
  questionsList: any[] = [];
  displayedQuestionList: any[] = [];
  id: string;
  collection = { count: 60, data: [] };
  initialList: Question[] = [];
  displayedColumns: string[] = ['name', 'type', 'action'];
  panelOpenState: boolean = false;
  public paramsSubscription: Subscription;
  nom: string = '';
  public section_name: string;
  url: string;
  technologiesList: Technology[] = [];
  displayedTechnologyList: Technology[] = [];
  dataSourceTech: MatTableDataSource<Technology>;
  subTechnoname: string;
  idSubtechnology: string;
  subTechnology: SubTechnology;
  form: FormGroup;
  isValidTitle: boolean = true;
  private messagesQL = {
    success: {
      update: ""
    },
    warning: {
      update: ""
    },
    error: {
      update: ""
    }
  };
  private messagesQE = {
    success: {
      delete: "Suppression de la question réussie"
    }
  };

  messages = {
    success: {
      delete: "Suppression de la sous technologie réussie"
    },
    errors:{
      delete: "Suppression de la sous technologie échoué"
    }
  };

  constructor(public dialog: MatDialog,
    private questionService: QuestionService,
    public filtersService: FiltersService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subTechnoService: SubTechnologyService,
    private translateService: TranslateService,
    private location: Location,
  ) { }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe((params: any) => {
      this.section_name = params['section_name'];
      this.id = params['id'];
      this.getQuestions();
      this.url = this.router.url;
      this.retrieveQuestions();
    });
    this.questionService.getValue().subscribe(val =>
      this.subTechnoname = val
    );
    this.initForm();
    this.getIdSubtechnology();
    // Retrieves translated message from i18n folder
    this.translateService.get('questions-library.messages').subscribe(
      messages => {
        this.messagesQL = Object.assign({}, messages);
      }
    );
    this.translateService.get('question-edit.messages').subscribe(
      messages => {
        this.messagesQE = Object.assign({}, messages);
      }
    );
  }
  /**
   * This function Defines subetechnoName for the defined formControl
   */
  initForm() {
    this.form = this.fb.group({
      idSubTechnology: null,
      title: ['', Validators.required]
    });
  }
  /**
   * This function returns Questions List
   */

  getQuestions() {
    this.questionService.getQuestions(this.id).subscribe(questions => {
      this.questionsList = questions;
      this.displayedQuestionList = this.questionsList;
      this.dataSource = new MatTableDataSource(this.questionsList);
      this.dataSource.paginator = this.paginator;
    });
  }


  /**
  * This function use 3 differents filtre to finally emit the questions list with chosen filters
  * @param initialList The initial questions list
  * @param displayedTestList The updated questions list
  * @param triggerFilters The event emitter
  */
  applyFilter() {
    this.displayedQuestionList = this.questionsList;
    this.dataSource = new MatTableDataSource(this.displayedQuestionList);
  }

  goToAddQuestion() {
    this.router.navigate(['/categories', this.section_name, 'subtechnology', this.id, 'question']);
  }

  goToQuestion(idquestion: string) {
    this.router.navigate(['/categories', this.section_name, 'subtechnology', this.id, 'question', idquestion]);
  }


  deleteQuestion(idquestion: string) {
    /** Shows Confirmation modal */
    let dialogRef = this.dialog.open(DeleteQuestionDialogComponent, {
      width: '50%',
      panelClass: 'myapp-no-border-radius-dialog',
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.questionService.deleteQuestion(idquestion).subscribe(
          data => {
            this.toastr.success(this.messagesQE.success.delete);
            this.retrieveQuestions();
          },
          err => {
            this.toastr.success(this.messagesQE.success.delete);
            this.retrieveQuestions();
          }
        )
      }
    });
  }

  /**
   * @description Gets users using user service
   */
  public retrieveQuestions(): void {
    this.questionService.getQuestions(this.id).subscribe(
      questions => {
        this.questionsList = questions;
        this.dataSource = new MatTableDataSource(this.questionsList);
        setTimeout(() => this.dataSource.paginator = this.paginator);
      },
      err => {
      }
    )
  }

  /**
   * @description Applies filter based on value from emitted event
   * @param event event emitted by a DOM element
  */
  public SearchFilter(event: any): void {
    if (typeof event === 'string') {
      event = event.trim().toLowerCase();
      this.dataSource.filter = event;
    }
  }

  onEditName(idSubtechnology: string) {
    this.subTechnoService.getSubTechnologyById(idSubtechnology).subscribe(
      data => {
        this.subTechnology = data;
        this.form = this.fb.group({
          idSubTechnology: this.subTechnology.idSubTechnology,
          title: [this.subTechnology.name, Validators.required],
        });
      });
  }

  getIdSubtechnology() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.onEditName(params['id'])
      }
      this.idSubtechnology = params['id'];
    });
  }
  validateInput() {
    if (this.form.value.title.length == 0)
      this.isValidTitle = false;
  }

  onSubmit() {
    this.validateInput();
    if (this.subTechnoname == this.form.value.title) {
      this.toastr.warning(this.messagesQL.warning.update);
    }
    if (this.isValidTitle && this.subTechnoname != this.form.value.title) {
      this.subTechnology.idSubTechnology = this.idSubtechnology;
      this.subTechnology.name = this.form.value.title;
      this.toastr.success(this.messagesQL.success.update);
      this.questionService.updateSubtechnologyName(this.subTechnology).subscribe(
        () => this.subTechnology.name = this.subTechnoname,
        err => {
          console.error(err);
        }
      )
    }
    else if (!this.form.value.title) {
      this.toastr.error(this.messagesQL.error.update);
    }
  }

  getImageUrl(imagePath: string): string {
    return environment.apiUrl + '/' + imagePath;
  }

  goBack() {
    this.location.back();
  }

  deleteSubTechno(idSubTechnology: string, subtechnologyName: string) {
    let dialogRef = this.dialog.open(DeleteSubTechnoDialogComponent, {
      width: '50%',
      panelClass: 'myapp-no-border-radius-dialog',
      data: { name: subtechnologyName }
    });
  
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.questionService.deleteSubTechnologyById(idSubTechnology).subscribe(
          (response) => {
            if (response.status === 200) {
              this.toastr.success(this.messages.success.delete);
              this.goBack()
              localStorage.removeItem('subTechnologyName');
            }
          },
          err => {
            this.toastr.error(this.messages.errors.delete);
          }
        );
      }
    });
  }

}
