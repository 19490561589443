import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-delete-sub-techno-dialog',
  templateUrl: './delete-sub-techno-dialog.component.pug',
  styleUrls: ['./delete-sub-techno-dialog.component.scss']
})
export class DeleteSubTechnoDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteSubTechnoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string }
  )
  { }

  ngOnInit() {
    if (!this.data.name) {
        this.data.name = localStorage.getItem('subTechnologyName') || '';
      }
  }

  /**
   * @description
   */
  public onSubmit(): void {
    this.dialogRef.close(true);
  }

  /**
   * @description
   */
  public onClose(): void {
    this.dialogRef.close();
  }

}
