import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { CandidatureService } from './shared/candidature.service';
import { Candidature } from './shared/candidature.model';
import { appRoutesNames } from '../../app.routes.names';
import { MatDialog } from '@angular/material';
import { ExportPdfDialog } from './expot-pdf-dialog/export-pdf-dialog.component';

@Component({
  selector: 'app-details-candidature',
  templateUrl: './details-candidature.component.pug',
  styleUrls: ['./details-candidature.component.scss']
})
export class DetailsCandidatureComponent implements OnInit {
  @ViewChild('resultPage', { read: ElementRef }) public resultPage: ElementRef;
  linkCandidaturesSynthesis: string;
  candidature: Candidature;
  profilePicture: any;
  url: string;
  rank:string
  constructor(
    private candidatureService: CandidatureService,
    private route: ActivatedRoute,
    private router: Router,
    public el: ElementRef,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.candidature = new Candidature();
    this.route.queryParams.subscribe(params => {
      return this.rank = params['rank']
    }
    )
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) =>
          this.candidatureService.getCandidatureByIdTest(params.get('id'))
        )
      )
      .subscribe(data => {
        this.candidature = data;
        this.candidatureService.getEosPhotoByEmail(this.candidature.email)
          .subscribe(
            data => {
              if( data['avatar_path'] == "https://www.pathcenter.co.il/wp-content/uploads/2014/03/user_icon.png" ) {
                this.profilePicture = "";
              }else{
                this.profilePicture= data.avatar_path;
                this.candidatureService.getProfilePictureAsbase64Data(this.profilePicture )
                .subscribe( dataBlob => {
                  var reader = new FileReader();
                  reader.readAsDataURL(dataBlob);
                  var self = this;
                  reader.onloadend = function() {
                    self.profilePicture = reader.result;
                  }
                });
              }

            }
          );
        this.candidatureService.getDYBByEmail(this.candidature.email)
        .subscribe(
          data => {
            this.candidature.dybLink = data.cvLink;
          },
          error => {
            if (error.status === 404) {
              this.candidature.dybLink = null;
            } else {
              console.error("An error occurred:", error);
            }
          }
        );
      });
      this.url = this.router.url;
  }


  public openDialog(info): void {
    /** Shows export pdf modal */
    let dialogRef = this.dialog.open(ExportPdfDialog, {
      width: '40%',
      panelClass: 'myapp-no-border-radius-dialog',
      data: { candidature:info }
    });

    

  }
  public getCandidateInfo(){
    let dialogRef = this.dialog.open(ExportPdfDialog, {
      width: '50%',
      panelClass: 'myapp-no-border-radius-dialog'
    });
    

  }
}
