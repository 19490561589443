import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from '@angular/material';
import { Subscription } from "rxjs";
import { ToastrService } from 'ngx-toastr';
import { QuestionService } from "../shared/question.service";
import { Technology } from "../shared/technology.model";
import { filterByAlphabetical } from '../../../shared/_helpers/functions'
import { DeleteTechnoDialogComponent } from "../delete-techno-dialog/delete-techno-dialog.component";

@Component({
  selector: "app-questions-library",
  templateUrl: "./questions-library.component.pug",
  styleUrls: ["./questions-library.component.scss"],
})
export class QuestionsLibraryComponent implements OnInit {
  paramsSubscription: Subscription;
  initialTechnologiesList: Technology[] = [];
  technologiesList = [];
  url: string;
  id: string;
  searchString: string = '';
  messages = {
    success: {
      delete: "Suppression de la technologie réussie"
    },
    errors:{
      delete: "Suppression de la technologie échoué"
    }
  };
  rows = [];

  public section_name: string;
  constructor(
    public router: Router,
    private questionService: QuestionService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe((params: any) => {
      this.section_name = params["section_name"];
      this.id = params["section_name"];
      this.getTechnologyById(this.section_name);
    },
      (err) => {
        console.error(err);
      });

    this.url = this.router.url;
    this.id = this.section_name;
    this.rows = this.getRows(this.technologiesList);
  }

  getTechnologyById(id: string) {
    this.questionService.getTechnologiesByCategory(id).subscribe(technos => {
      filterByAlphabetical(technos)
      this.technologiesList = technos;
      this.initialTechnologiesList = [...technos];
      this.rows = this.getRows(this.technologiesList);
    })
  }

  addSubTechno(idTechno) {
    localStorage.setItem('idTechnology', idTechno.toString());

    this.router.navigate([
      "/categories/" + this.section_name + "/subtechnology"
    ])
  }

  sousTechno(id: string, name: string) {
    localStorage.setItem('subTechnologyName', name);
    this.router.navigate([
      "/categories/" + this.section_name + "/subtechnology",
      id,
    ]);
    this.questionService.loadValue(name);
  };

  goToAddTechology() {
    this.router.navigate(['/categories', this.id, "technology"]);
  };

  getSearchFilterValue(event) {
    this.searchString = event;
    // Rechercher tes technologies dont le nom contient le searchText ou les technos où les subtechno contient le searchText
    let technologiesListfilter = this.initialTechnologiesList.filter(techno => techno.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
      techno.subTechnologies[0] !== null && techno.subTechnologies.some(sunTechno => sunTechno.name.toLowerCase().includes(this.searchString.toLowerCase())));
    // filtrer le tableu des subtechno pour n'avoir que les subtechnos qui contiennet le searchText
    let filterBySubTechno = technologiesListfilter.map(techno => {
      if (techno.name.toLowerCase() === this.searchString.toLowerCase()) {
        return techno;
      } else {
        return {
          ...techno,
          subTechnologies: techno.subTechnologies.filter(subTechno => techno.subTechnologies[0] !== null && subTechno.name.toLowerCase().includes(this.searchString.toLowerCase()))
        };
      }
    });
    this.technologiesList = this.searchString ? filterBySubTechno : this.initialTechnologiesList
    this.rows = this.getRows(this.technologiesList);
  }

  getRows(technologiesList) {
    const rows = [];
    for (let i = 0; i < technologiesList.length; i += 4) {
      rows.push(technologiesList.slice(i, i + 4));
    }
    return rows;
  }

  deleteTechno(idTechnology: string, technologyName: string) {
    let dialogRef = this.dialog.open(DeleteTechnoDialogComponent, {
      width: '50%',
      panelClass: 'myapp-no-border-radius-dialog',
      data: { name: technologyName }
    });
  
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.questionService.deleteTechnologyById(idTechnology).subscribe(
          (response) => {
            if (response.status === 200) {
              this.toastr.success(this.messages.success.delete);
              this.technologiesList = this.technologiesList.filter(
                tech => tech.idTechnology !== idTechnology && !tech.is_deleted
              );
              this.rows = this.getRows(this.technologiesList);
            }
          },
          err => {
            this.toastr.error(this.messages.errors.delete);
          }
        );
      }
    });
  }

}
