import { Component, OnInit } from '@angular/core';
import { appRoutesNames } from '../../app.routes.names';
import { AuthenticationService } from '../login/shared/authentication.services';
import { Router } from '@angular/router';
import { RolesService } from 'src/app/shared/services/roles.service';

export interface CustomRoute {
  path: string;
  label: string;
  icon: string;
  icon_hover: string;
}

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.pug',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  constructor(
    private auth: AuthenticationService,
    private roles: RolesService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.over = new Array(this.routes.length);
    this.over.fill(false);
  }

  over: boolean[];
  hover: boolean;
  showFiller = true;

  routes: CustomRoute[] = [
    {
      path: appRoutesNames.CANDIDATURES,
      label: 'menus.main.reception',
      icon:  'icon_dashboard.svg',
      icon_hover: 'icon_dashboard.hover.svg'
    },
    {
      path: appRoutesNames.PROFILE,
      label: 'menus.main.profile',
      icon:  'icon_customers.svg',
      icon_hover: 'icon_customers.hover.svg'
    },
    {
      path: appRoutesNames.TESTS,
      label: 'menus.main.remote-test',
      icon:  'icon_library.svg',
      icon_hover: 'icon_library.hover.svg'
    },
    {
      path: appRoutesNames.QUESTIONSLIBRARY,
      label: 'menus.main.technologies',
      icon:  'icon_study.svg',
      icon_hover: 'icon_study.hover.svg'
    },
    {
      path: appRoutesNames.USERS,
      label: 'menus.main.users',
      icon: 'icon_customers.svg',
      icon_hover:'icon_customers.hover.svg'
    }
  ];

  ngOnInit() {
    this.showFiller = true;
  }

  logout() {
    localStorage.removeItem('subTechnologyName');
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }  

  hasRight(customRoute: CustomRoute): boolean{

    if( (this.roles.isAdmin()) &&
        (customRoute.path === appRoutesNames.CANDIDATURES || customRoute.path === appRoutesNames.PROFILE || customRoute.path === appRoutesNames.QUESTIONSLIBRARY || customRoute.path === appRoutesNames.TESTS || customRoute.path === appRoutesNames.USERS)){
        return true;
    }

    if(this.roles.isManager() && (customRoute.path === appRoutesNames.CANDIDATURES || customRoute.path === appRoutesNames.PROFILE || customRoute.path == appRoutesNames.TESTS)){
        return true;
    }

    if (this.roles.isReader() && (customRoute.path === appRoutesNames.CANDIDATURES)) {
      return true;
    }

   return false;
  }
}
