import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Candidature } from './candidature';

@Injectable({
  providedIn: 'root'
})
export class CandidaturesService {
  readonly apiBaseUrl = environment.apiUrl;
  readonly getCandidatesThatCompletedTestsUrl = `${
    this.apiBaseUrl
  }/candidates/withTests`;
  readonly getCandidaturesManagerByIdUrl = `${this.apiBaseUrl}/candidates/manager/`;
  readonly getCandidateRankByTestAndSubTechUrl= `${this.apiBaseUrl}/test/rank/`;
  readonly getDYBByEmailUrl = `${this.apiBaseUrl}/test/dyb/`;
  readonly exportExcelUrl = `${this.apiBaseUrl}/candidates/testDetails/export-answer-sheet`;

  constructor(private http: HttpClient) {}

  public getCandidatures(): Observable<Candidature[]> {
    return this.http.get<Candidature[]>(
      `${this.getCandidatesThatCompletedTestsUrl}`
    );
  }
  getCandidateRankByTestAndSubTech(idSubTech,idTest): Observable<any> {
    return this.http.get<any>(
      `${this.getCandidateRankByTestAndSubTechUrl}${idSubTech}/${idTest}`
    );
  }

  public getAnswerSheet(idtest: string):Observable<any>{
    return this.http.get<any>(`${this.apiBaseUrl}/test/answer-sheet/${idtest}`);
  }

  public getCandidatureByIdTest(idTest: string): Observable<Candidature> {
    return this.http.get<Candidature>(
      `${this.getCandidatesThatCompletedTestsUrl}`
    );
  }
  /**
   * This function gets manager's candidates 
   * @param idEmployee is the managerId 
   * @returns from a get request list of details of passed tests
   */
  public getCandidaturesManagerById(idEmployee: string): Observable<any> {
    return this.http.get<any>(`${this.getCandidaturesManagerByIdUrl}${idEmployee}`);
  }

  public getDYBByEmail(emailAdress: string):Observable<any>{
    return this.http.get(`${this.getDYBByEmailUrl}${emailAdress}`);
  }

  public exportAnswerSheetToExcel(answerSheet: any, candidateDetails: any, reportType: string): Observable<Blob> {
    const exportData = { answerSheet, candidateDetails, reportType };
    return this.http.post(this.exportExcelUrl, exportData, { responseType: 'blob' });
  }
}
