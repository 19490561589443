import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Question } from './question.model';
import { Technology } from './technology.model';
import { SubTechnology } from '../../candidatures/shared/subtechnology';
import { TechCategory } from './techCategory.model';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  readonly apiBaseUrl = environment.apiUrl;
  readonly getQuestionsUrl = `${this.apiBaseUrl}/questions/bySubTechnology/`;
  readonly getTechnologiesUrl = `${this.apiBaseUrl}/technologies/`;
  readonly getQuestionByIdUrl = `${this.apiBaseUrl}/questions/`;
  readonly createOrEditQuestionByIdUrl = `${this.apiBaseUrl}/questions/`;
  readonly getTechCategoriesUrl = `${this.apiBaseUrl}/categories`;
  readonly deleteTechnologiesUrl = `${this.apiBaseUrl}/technologies/removeTechnology/`;
  readonly deleteSubTechnologiesUrl = `${this.apiBaseUrl}/subtechnologies/removeSubTechnology/`;
  private _subTechnoName = new BehaviorSubject<string>("");


  constructor(private http: HttpClient) {}

  getTechCategories(): Observable<TechCategory[]> {
    return this.http.get<TechCategory[]>(`${this.getTechCategoriesUrl}`);
  }
  getTechnologyById(id:string): Observable<Technology[]> {
    return this.http.get<Technology[]>(`${this.getTechnologiesUrl}/category/${id}/subtechnologies/`);
  }
  getTechnologiesByCategory(id:string): Observable<Technology[]> {
    return this.http.get<Technology[]>(`${this.getTechnologiesUrl}/category/${id}`);
  }
  getQuestions(id: string): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.getQuestionsUrl}${id}`);
  }
  getQuestionsBySubtechnology(id: string): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.apiBaseUrl}/questions/bySubTechnology/${id}`);
  }

  getQuestionById(id: string): Observable<Question> {
    return this.http.get<Question>(`${this.getQuestionByIdUrl}${id}`)
  }

  createQuestion(question: any) {
    let createdQuestion = this.http.post(`${this.apiBaseUrl}/questions`, question);
    return createdQuestion;
  }

  updateQuestion(question: Question, idQuestion:string) {
    let updatedQuestion = this.http.put(`${this.apiBaseUrl}/questions/${idQuestion}`, question );
    return updatedQuestion;
  };

  deleteQuestion(idQuestion: string): Observable<any> {
    let deletedQuestion = this.http.delete(`${this.apiBaseUrl}/questions/${idQuestion}`);
    return deletedQuestion;
  }


  resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
		return new Promise((resolve, reject) => {
			let image = new Image();
			image.src = URL.createObjectURL(file);
			image.onload = () => {
				let width = image.width;
				let height = image.height;

				if (width <= maxWidth && height <= maxHeight) {
					resolve(file);
				}
				let newWidth;
				let newHeight;

				if (width > height) {
					newHeight = height * (maxWidth / width);
					newWidth = maxWidth;
				} else {
					newWidth = width * (maxHeight / height);
					newHeight = maxHeight;
				}
				let canvas = document.createElement('canvas');
				canvas.width = newWidth;
				canvas.height = newHeight;
				let context = canvas.getContext('2d');
				context.drawImage(image, 0, 0, newWidth, newHeight);
				canvas.toBlob(resolve, file.type);
			};
			image.onerror = reject;
		});
  }
  updateSubtechnologyName(subTechnology : SubTechnology):Observable<any>{
    let updateSubtechno = this.http.patch(`${this.apiBaseUrl}/subtechnologies/${subTechnology.idSubTechnology}`, subTechnology);
    return updateSubtechno;
  }
  loadValue(val: string) {
        this._subTechnoName.next(val);
	}
	getValue(): Observable<string>{
		return this._subTechnoName.asObservable();
	}
  deleteImage(questionPictureName): Observable<any>{
    let deleteImage=this.http.delete(`${this.apiBaseUrl}/questions/delete-image/${questionPictureName}`)
    return deleteImage
  }
  deleteTechnologyById(idTechnology: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.deleteTechnologiesUrl}${idTechnology}`,{ observe: 'response' });
  }
  deleteSubTechnologyById(idSubTechnology: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.deleteSubTechnologiesUrl}${idSubTechnology}`,{ observe: 'response' });
  }
}
